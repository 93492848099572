<template>
   <div class="container-fluid timeline-page">
            <b-row >
               <b-col lg="6">
                  <card class=" card-block card-stretch card-height">
                     <template v-slot:headerTitle>
                           <h4 class="card-title">Dots Timeline</h4>
                      </template>
                     <template v-slot:body>
                        <div class="iq-timeline0 m-0 d-flex align-items-center justify-content-between position-relative">
                           <ul class="list-inline p-0 m-0">
                              <li>
                                 <div class="timeline-dots1 border-primary text-primary"><i class="ri-login-circle-line"></i>
                                 </div>
                                 <h6 class="float-left mb-1">Client Login</h6>
                                 <small class="float-right mt-1">24 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                                 </div>
                              </li>
                              <li>
                                 <div class="timeline-dots1 border-success text-success"><i class="ri-shape-line"></i>
                                 </div>
                                 <h6 class="float-left mb-1">Scheduled Maintenance</h6>
                                 <small class="float-right mt-1">23 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                                 </div>
                              </li>
                              <li>
                                 <div class="timeline-dots1 border-danger text-danger"><i class="ri-clockwise-2-fill"></i>
                                 </div>
                                 <h6 class="float-left mb-1">Dev Meetup</h6>
                                 <small class="float-right mt-1">20 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans <a href="#">gummi bears</a>gummi bears jelly lollipop apple</p>
                                    <div class="iq-media-group">
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/05.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/06.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/07.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/08.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/09.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/10.jpg" alt="">
                                       </a>
                                    </div>
                                 </div>
                              </li>
                              <li>
                                 <div class="timeline-dots1 border-primary text-primary"><i class="ri-phone-fill"></i>
                                 </div>
                                 <h6 class="float-left mb-1">Client Call</h6>
                                 <small class="float-right mt-1">19 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                                 </div>
                              </li>
                              <li>
                                 <div class="timeline-dots1 border-warning text-warning"><i class="ri-book-open-fill"></i>
                                 </div>
                                 <h6 class="float-left mb-1">Mega event</h6>
                                 <small class="float-right mt-1">15 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </template>
                  </card>
               </b-col>
               <b-col lg="6">
                  <card class=" card-block card-stretch card-height">
                     <template v-slot:headerTitle>
                           <h4 class="card-title">Small Dots Timeline</h4>
                        </template>
                     <template v-slot:body>
                        <div class="iq-timeline0 m-0 d-flex align-items-center justify-content-between position-relative">
                           <ul class="list-inline p-0 m-0">
                              <li>
                                 <div class="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                 <h6 class="float-left mb-1">Client Login</h6>
                                 <small class="float-right mt-1">24 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                                 </div>
                              </li>
                              <li>
                                 <div class="timeline-dots timeline-dot1 border-success text-success"></div>
                                 <h6 class="float-left mb-1">Scheduled Maintenance</h6>
                                 <small class="float-right mt-1">23 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                                 </div>
                              </li>
                              <li>
                                 <div class="timeline-dots timeline-dot1 border-danger text-danger"></div>
                                 <h6 class="float-left mb-1">Dev Meetup</h6>
                                 <small class="float-right mt-1">20 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans <a href="#">gummi bears</a>gummi bears jelly lollipop apple</p>
                                    <div class="iq-media-group">
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/05.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/06.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/07.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/08.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/09.jpg" alt="">
                                       </a>
                                       <a href="#" class="iq-media">
                                          <img class="img-fluid avatar-40 rounded-circle" src="../../../../assets/images/user/10.jpg" alt="">
                                       </a>
                                    </div>
                                 </div>
                              </li>
                              <li>
                                 <div class="timeline-dots timeline-dot1 border-primary text-primary"></div>
                                 <h6 class="float-left mb-1">Client Call</h6>
                                 <small class="float-right mt-1">19 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                                 </div>
                              </li>
                              <li>
                                 <div class="timeline-dots timeline-dot1 border-warning text-warning"></div>
                                 <h6 class="float-left mb-1">Mega event</h6>
                                 <small class="float-right mt-1">15 November 2019</small>
                                 <div class="d-inline-block w-100">
                                    <p>Bonbon macaroon jelly beans gummi bears jelly lollipop apple</p>
                                 </div>
                              </li>
                           </ul>
                        </div>
                      </template>
                  </card>
               </b-col>
            </b-row>
      </div>
</template>
<script>
export default {
   name:'Timeline1'
}
</script>